<template>
    <div class="page_wei">
        <WidgetLayout>
            <template #content>
                <div class="search_letter">
                    <div class="search_letter_box">
                        <div class="letter_content">
                            <div class="content_title">JOURNALS A-Z</div>
                            <div v-for="(item, index) in SEARCHLETTER" :key="index"
                                :class="getJournalData.journalName == item ? 'search_is_active letter_item' : 'letter_item'"
                                @click="onLetterChange(item)">{{ item }}</div>
                        </div>
                        <div class="letter_btn">
                            <el-button type="primary" :size="btnSize"
                                @click="onLetterChange(getJournalData.journalName)">Reset</el-button>
                        </div>
                    </div>
                </div>

                <div class="yian_data" ref="contentRef">
                    <div class="yian_content">
                        <div class="title">SEARCH RESULTS: <span class="storng_text">{{ getJournalData.total }}</span>
                        </div>
                        <div class="total_box">
                            <div class="total_title">JOURNAL</div>
                            <div class="total_page">Results: {{ getJournalData.current }} - 10 of {{
                                getJournalData.total }}</div>
                        </div>

                        <div class="yian_table">
                            <DataTableVue :tableData="getJournalData.records" @update:sort="onTableSortChange">
                            </DataTableVue>
                        </div>

                        <div class="yian_pagination">
                            <DataPaginationVue :total="getJournalData.total" @update:pagination="onPaginationChange">
                            </DataPaginationVue>
                        </div>
                    </div>
                </div>
            </template>
        </WidgetLayout>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { updateUrlParameter } from '@/utils/utils';
import { NODATA_PAGE_NAME, SEARCHLETTER } from "./data"
import WidgetLayout from "./layout.vue"
import DataTableVue from "./components/dataTable.vue";
import DataPaginationVue from "./components/dataPagination.vue";

export default {
    name: "joint",
    components: {
        WidgetLayout,
        DataTableVue,
        DataPaginationVue
    },
    computed: {
        ...mapGetters(['getJournalData', 'getJourSearchParams']),
    },
    data() {
        return {
            SEARCHLETTER,
            btnSize: "small"
        }
    },
    methods: {
        ...mapActions(['fetchJournalData']),
        async onPaginationChange(page) {
            this.changePageContentTop()

            this.$store.commit('setJourSearchParams', { page });
            updateUrlParameter('p', page);

            await this.fetchJournalData();
        },
        async onLetterChange(letter) {
            if (letter == this.getJournalData.journalName) {
                this.$store.commit('setJourSearchParams', { journalName: '', page: 1 });
                updateUrlParameter('jn', '');
            } else {
                this.$store.commit('setJourSearchParams', { journalName: letter, page: 1 });
                updateUrlParameter('jn', letter);
            }
            updateUrlParameter('p', 1);

            await this.fetchJournalData();
        },
        async onTableSortChange(order) {
            if (order === 'ascending') {
                delete this.getJourSearchParams.desc;
                this.getJourSearchParams.asc = 'publisher_name';
            } else if (order === 'descending') {
                delete this.getJourSearchParams.asc;
                this.getJourSearchParams.desc = 'publisher_name';
            } else {
                delete this.getJourSearchParams.desc;
                delete this.getJourSearchParams.asc;
            }

            await this.fetchJournalData();
        },
        changePageContentTop() {
            const rect = this.$refs.contentRef.getBoundingClientRect()
            if (rect.top < 0) {
                window.scroll({
                    top: rect.height + rect.top,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        },
        updatePagerCount() {
            const width = window.innerWidth;
            this.btnSize = width < 768 ? 'small' : '';
        },
        transformParams(originalObj) {
            return {
                page: parseInt(originalObj.p),
                pageSize: parseInt(originalObj.ps),
                commonCondition: originalObj.cc,
                journalName: originalObj.jn
            };
        },
        async recoverDataList() {
            if (this.getJourSearchParams.journalName == '' && this.getJourSearchParams.page == 1 && this.getJourSearchParams.commonCondition == "") {
                const params = this.transformParams(this.$route.query)

                this.$store.commit('setJourSearchParams', params);

                await this.fetchJournalData();
            } else {
                return this.$router.push('/' + NODATA_PAGE_NAME)
            }
        }
    },
    mounted() {
        if (Object.keys(this.getJournalData).length === 0) {
            // 如果数据为空 判断vuex中是否有数据 以及路由query中是否带参 来判断是否是从其他页面跳转回来 (针对移动端微信中打开默认 _self 跳转)
            this.recoverDataList()
        }

        this.updatePagerCount();
        window.addEventListener('resize', this.updatePagerCount);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updatePagerCount);
    }
}
</script>

<style lang="scss">
@import "../../assets/css/minix.scss";

.page_wei {

    .yian_form,
    .yian_video {
        margin-top: 50px;
    }

    .search_is_active {
        background-color: #1da6b8 !important;
        color: white;
    }

    .search_letter {
        margin-top: 20px;
        display: flex;
        justify-content: center;

        .search_letter_box {
            @include responsive-width(1200px);
            display: flex;
        }

        .letter_content {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .content_title {
                font-size: 16px;
                font-weight: bold;
                margin-right: 16px;
            }

            .letter_item {
                width: 30px;
                height: 30px;
                border-radius: 6px;
                margin-right: 3px;
                background-color: white;
                transition: all .3s;
                display: flex;
                justify-content: center;
                align-items: center;

                &:hover {
                    cursor: pointer;
                    background-color: #1da6b8;
                    color: white;
                }
            }
        }

        .letter_btn {
            margin-left: 20px;
        }
    }

    .yian_data {
        display: flex;
        justify-content: center;
        margin-top: 50px;

        .yian_content {
            @include responsive-width(1200px);

            .title {
                font-family: 'Bebas Neue';
                font-size: 30px;
                font-weight: bold;
                border-left: 4px solid #1ba4b7;
                padding-left: 10px;

                .storng_text {
                    color: #24a8ba;
                }
            }

            .total_box {
                display: flex;
                justify-content: space-between;
                margin: 20px;

                .total_title {
                    font-size: 20px;
                }

                .total_page {
                    font-size: 16px;
                }
            }

            .yian_pagination {
                margin-top: 30px;
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}

@media (max-width: 768px) {
    .search_letter {
        .search_letter_box {
            align-items: flex-end;
            flex-direction: column;
        }

        .letter_btn {
            margin-top: 10px;
        }
    }

    .yian_data {
        padding: 0 20px;

        .yian_content {
            .title {
                font-size: 24px !important;
            }
        }
    }
}
</style>