// 邮箱格式验证函数
export function isValidateEmail (email) {
    const mailReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
    if (email.trim()) {
        if (!mailReg.test(email)) {
            return {
                showClose: true,
                duration: 1500,
                message: "Please enter the correct email address."
            };
        }
    }
    return null;
};
