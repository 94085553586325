<template>
    <div class="contact_us">
        <el-dialog title="" :visible.sync="dialogVisible" :width="width" :top="'5vh'" v-loading="loading">
            <div class="us_content">
                <div class="content_left">
                    <div class="left_tips">Choose your country/region to get support from your regional sales office.
                    </div>

                    <div class="left_form_content">
                        <FormBoxVue :title="'Sales Region'">
                            <template #content>
                                <el-select v-model="formData.supportOrigin" placeholder="" style="width: 100%;">
                                    <el-option v-for="(item, index) in MC" :key="index" :label="item" :value="item">
                                    </el-option>
                                </el-select>
                            </template>
                        </FormBoxVue>

                        <FormBoxVue :title="'Name'" :isRequired="true">
                            <template #content>
                                <el-input v-model="formData.userName" placeholder=""></el-input>
                            </template>
                        </FormBoxVue>

                        <FormBoxVue :title="'Email'" :isRequired="true">
                            <template #content>
                                <el-input v-model="formData.userEmail" placeholder=""></el-input>
                            </template>
                        </FormBoxVue>

                        <FormBoxVue :title="'Country/Region'">
                            <template #content>
                                <el-select v-model="formData.country" filterable placeholder="" style="width: 100%;">
                                    <el-option v-for="(item, index) in countriesList" :key="index" :label="item"
                                        :value="item">
                                    </el-option>
                                </el-select>
                            </template>
                        </FormBoxVue>

                        <FormBoxVue :title="'Phone number'">
                            <template #content>
                                <el-input v-model="formData.userPhone" placeholder=""></el-input>
                            </template>
                        </FormBoxVue>

                        <FormBoxVue :title="'Message'">
                            <template #content>
                                <el-input type="textarea" :rows="3" v-model="formData.remark" placeholder=""></el-input>
                            </template>
                        </FormBoxVue>
                    </div>

                    <div class="left_hint">
                        By submitting this form, I agree that Contrimetric may use my name, email address, phone number,
                        and country of
                        residence to provide support.
                        I agree that Contrimetric may process said data using third-party services for this purpose in
                        accordance with the
                        <span class="storng_text" @click="goToPolicy">Contrimetric Privacy policy</span>.
                    </div>
                </div>
                <div class="content_right">
                    <div class="right_title">{{ onsultantObj[formData.supportOrigin].rightTitle }}</div>
                    <div class="right_content">If you need any help with Contrimetric plugin technical any special needs
                        or suggestions
                        for
                        Contrimetric tools,
                        please contact us by completing and submitting the request form or emailing us.</div>
                    <div class="right_support1">
                        {{ onsultantObj[formData.supportOrigin].rightSupport }}<br>
                        <a :href="`mailto:${onsultantObj[formData.supportOrigin].email}?subject=&body=`">{{
                            onsultantObj[formData.supportOrigin].email }}</a>
                    </div>
                    <div class="right_author">
                        <div class="author_image">
                            <img :src="onsultantObj[formData.supportOrigin].image" alt="">
                            <!-- <el-image :src="onsultantObj[formData.supportOrigin].image" :preview-src-list="[onsultantObj[formData.supportOrigin].image]"></el-image>-->
                        </div>
                        <div class="author_info">
                            <div class="info_name">{{ onsultantObj[formData.supportOrigin].name }}</div>
                            <div class="info_rank">{{ onsultantObj[formData.supportOrigin].rank }}</div>
                        </div>
                    </div>
                    <div class="right_support1">
                        Tech Support: <br>
                        <a href="mailto:contact@contrimetric.com?subject=&body=">contact@contrimetric.com</a>
                    </div>
                </div>
            </div>

            <div slot="footer" class="dialog_footer">
                <el-button type="primary" @click="onDialogSubmit">Submit</el-button>
                <el-button @click="dialogVisible = false">Cancel</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { MC, countriesList, onsultantObj } from '../data';
import { applyJournal } from '@/api/widget';
import { isValidateEmail } from "@/utils/validate"
import FormBoxVue from './formBox.vue';

export default {
    components: {
        FormBoxVue
    },
    data() {
        return {
            MC,
            countriesList,
            onsultantObj,
            dialogVisible: false,
            loading: false,
            defaultRegion: "Southeast Asia",
            formData: {
                supportOrigin: "Southeast Asia",
                userName: "",
                userEmail: "",
                country: "",
                userPhone: "",
                remark: ""
            },
            width: "1200px"
        }
    },
    methods: {
        openDialog(row) {
            this.clearFormData()
            this.dialogVisible = true
        },
        validateForm() {
            if (this.formData.userName == '' || this.formData.userEmail == '') {
                this.$message.warning("Please enter name and email")

                return false
            }
            return true
        },
        clearFormData() {
            for (let key in this.formData) {
                if (key === 'supportOrigin') {
                    this.formData[key] = this.defaultRegion;
                } else {
                    this.formData[key] = "";
                }
            }
        },
        async onDialogSubmit() {
            if (this.validateForm()) {
                const validationResult = isValidateEmail(this.formData.userEmail)

                if (!validationResult) {
                    this.loading = true

                    await applyJournal(this.formData).then(() => {
                        this.$message.success("Submit successfully")
                    }).catch((err) => {
                        this.$message.error(err || 'Unknown error')
                    }).finally(() => {
                        this.loading = false
                        this.dialogVisible = false

                        this.clearFormData()
                    })
                } else {
                    this.$message.warning("Please enter the correct email address.")
                }
            }
        },
        goToPolicy() {
            window.open('https://contrimetric.com/privacy', '_blank')
        },
        updatePagerCount() {
            const width = window.innerWidth;
            this.width = width < 768 ? '100%' : '1200px';
        },
    },
    mounted() {
        this.updatePagerCount();
        window.addEventListener('resize', this.updatePagerCount);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updatePagerCount);
    },
}
</script>

<style lang="scss">
.contact_us {
    .us_content {
        padding: 0 20px;
        display: flex;
        justify-content: space-around;
        line-height: 1.5;

        .content_left,
        .content_right {
            flex: 0 0 43%
        }

        .content_left {
            .left_tips {
                font-size: 16px;
                color: black;
            }

            .left_hint {
                color: #5e5e60;

                .storng_text {
                    color: black;
                    text-decoration: underline;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }

        .content_right {

            .right_title,
            .right_content,
            .right_support1,
            .right_support2,
            .right_author {
                margin-bottom: 30px;
                font-size: 16px;
            }

            .right_title {
                color: black;
                font-weight: bold;
            }

            .right_content {
                color: #5e5e60;
                line-height: 1.8;
            }

            .right_support1,
            .right_support2 {
                color: #5e5e60;

                a {
                    color: black;
                    text-decoration: underline;
                }
            }

            .right_author {
                border: 1px dashed #1da6b8;
                padding: 40px 30px;
                display: flex;
                align-items: center;
                border-radius: 10px;

                .info_name {
                    font-weight: bold;
                    color: #1da6b8;
                    margin-bottom: 4px;
                    font-size: 16px;
                }

                .info_rank {
                    color: #5e5e60;
                }

                .author_image {
                    width: 96px;
                    height: 96px;
                    margin-right: 20px;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }
                }
            }
        }
    }

    .dialog_footer {
        text-align: left;
        margin-left: 60px;
        margin-bottom: 20px;
    }
}

@media (max-width: 768px) {
    .contact_us {
        .us_content {
            flex-direction: column-reverse;

            .content_right {
                .right_author {
                    padding: 30px 20px;

                    .author_image {
                        width: 72px;
                        height: 72px;
                        margin-right: 0;
                    }

                    .author_info {
                        margin-left: 20px;
                        flex: 1;
                    }
                }
            }
        }
    }
}
</style>